#results {
    width: 100%;
    padding: 80px 0 0px 0;
    .content {
        width: 100%;
        text-align: center;
        h1 {
            color: $black;
            text-align: center;
            margin-bottom: 60px;
            .bold3 {
                font-weight: 700;
                color: $green;
                text-transform: uppercase;
            }
        }
        p {
            color: $black;
        }
        img {
            margin-bottom: 50px;
            
            @include mobile {
                margin-bottom: 15px;
            }
            @include small-mobile {
                margin-bottom: 15px;
            }
        }
    }
}