/*---------------
  FONTS
---------------*/
$primary-font: 'Ubuntu', sans-serif;

/*---------------
  MEDIA QUERIES
---------------*/
$mobile-width: 480px;
$tablet-width: 768px;
$small-desktop-width: 992px;
$desktop-width: 1200px;
$large-desktop-width: 1440px;

/*---------------
  COLORS
---------------*/
$green: #95CE3A;
$white: #FFF;
$overlay: rgba(255, 255, 255, 0.5);
$black: #000;
$grey1: #666;  
$grey2: #EEE;  
$grey3: #F5F5F5;  

/*---------------
  GENERAL
---------------*/
$transition: all .3s ease-in-out;



