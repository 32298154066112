/* BASE - Base styles */
@import 'base/_variables.scss';
@import 'base/_mixins.scss';
@import 'base/_base.scss';
@import 'base/_bootstrap.scss';
@import 'base/_typography.scss';

/* LAYOUTS - Site layouts */
@import 'layouts/_header.scss';
@import 'layouts/_nav.scss';
@import 'layouts/_navSticky.scss';
@import 'layouts/_printing.scss';
@import 'layouts/_services.scss';
@import 'layouts/_results.scss';
@import 'layouts/_news.scss';
@import 'layouts/_contact.scss';
@import 'layouts/_footer.scss';

/* MODULES - Site components */
@import 'modules/_photogrid.scss';
@import 'modules/_mobileSlider.scss';
@import 'modules/sidenav.css';
@import 'modules/animations.css';
