#printing {
    background-color: $black;
    padding: 80px 0 135px 0;
    
    @include mobile {
        padding: 80px 0 80px 0;
    }
    @include small-mobile {
        padding: 80px 0 80px 0;
    }
    
    .content {
        margin: auto;
        display: inline;
        text-align: center;
        
        @include mobile {
            text-align: left;
        }
        @include small-mobile {
            text-align: left;
        }
        p {
            padding-right: 10%;
            padding-left: 10%;
            margin-bottom: 40px;
            
            @include mobile {
                padding: 0
            }
            @include small-mobile {
                padding: 0
            }
        }
        .buttons {  
            margin: 0 auto;
                  
            a:link {
                color: $white;
                text-decoration: none;
            }
            a:visited {
                color: $white;
                text-decoration: none;
            }
            a:hover {
                color: $white;
                text-decoration: none;
            }
            a:active {
                color: $white;
                text-decoration: none;
            }
            
            a  {
                h5 {
                    background-color: $green;
                    text-align: center;
                    
                    @include large-desktop {
                        margin: 20px 10px 0 10px;
                        width: 25%;
                        max-width: 185px;
                        display: inline-block;
                    }
                    @include desktop {
                        margin: 20px 10px 0 10px;
                        width: 25%;
                        max-width: 185px;
                        display: inline-block;
                    }
                    @include small-desktop {
                        margin: 20px 10px 0 10px;
                        width: 25%;
                        max-width: 185px;
                        display: inline-block;
                    }
                    @include tablet {
                        display: block;
                        margin: 30px auto;
                        width: 60%;
                    }
                    @include mobile {
                        display: block;
                        margin: 30px auto;
                        width: 60%;
                    }
                    @include small-mobile {
                        display: block;
                        margin: 30px auto;
                        width: 100%;
                    }
                }
                .button1 {
                    padding: 12px 0;
                    border: 4px solid $green;
                    border-radius:0px;
                    transition: all .3s ease-in-out;
                        -moz-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                }
                .button1:hover {
                    background-color: $black;
                    border: 4px solid $grey1;
                }
                .button2 {
                    padding: 12px 0;
                    border: 4px solid $green;
                    border-radius:0px;
                    transition: all .3s ease-in-out;
                        -moz-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                }
                .button2:hover {
                    background-color: $black;
                    border: 4px solid $grey1;
                }
            }
        }
    }
}