#photogrid {
    
    img {
        width: 1140px;
        max-width: 100%;
        display: block;
        margin: 0 auto 50px auto;
        
    }
}

/*#photogrid {

    margin: 0 auto 40px auto;
    z-index: 10;
    .content {
        width: 100%;
        padding-bottom: 10px;
        .row1 {  
            display: flex;
            justify-content: space-evenly;
            .largephoto {
                width: 481px;
                height: 319px;
                margin-right: 3px;
            }
            .photocolumns {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .photocolumn1 {
                    display: flex;
                    flex-direction: column;
                    img {
                        // margin-right: 7px;
                        justify-content: space-between;
                    }
                    width: 317px;
                    height: 319px;
                    .pmargin {
                        margin-bottom: 4px;
                    }
                }
                .photocolumn2 {
                    display: flex;
                    flex-direction: column;
                    width: 317px;
                    height: 319px;
                    margin-left: 4px;
                    .pmargin {
                        margin-bottom: 4px;
                    }
                }
            }
        }
        .photorow1 {
            display: flex;
            padding-top: 7px;
            flex-wrap: nowrap;
            justify-content: space-evenly;
        }
    } 
}*/