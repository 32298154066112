#news {
    width: 100%;
    background: $grey3;
    padding: 80px 0 40px 0;
    
    .content {
        width: 100%;
        text-align: center;
        
        @include mobile {
            text-align: left;
        }
        @include small-mobile {
            text-align: left;
        }
        h1 {
            color: $black;
        }
        p {
            color: $black;
            max-width: 80%;
            margin: 0 auto;
            margin-bottom: 20px;
            
            @include mobile {
                max-width: 100%;
            }
            @include small-mobile {
                max-width: 100%;
            }
        }
        .news-images {
            margin: 50px 0;
        }
        .mural {
            margin-bottom: 80px;
        }
        /*#in-addition {
            padding-top: 20px;
        }
        a {
            .button3 {
                background-color: $green;
                width: 200px;
                padding: 12px 0;
                border: 4px solid $green;
                display: block;
                margin: 40px auto 50px auto;
                text-align: center;
                border-radius: 0;
                transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;
                
                @include mobile {
                    margin: 40px 0 50px 0;
                    width: 200px;
                }
                @include small-mobile {
                    margin: 40px 0 50px 0;
                    width: 200px;
                }
            }
            .button3:hover {
                background-color: $black;
                border: 4px solid $black;
            }
        }
        a:link {
            color: $white;
            text-decoration: none;
            transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -webkit-transition: all .3s ease-in-out;
        }
        a:visited {
            color: $white;
            text-decoration: none;
        }
        a:hover {
            color: $green;
            text-decoration: none;
        }
        a:active {
            color: $white;
            text-decoration: none;
        }
        .contact-images {
            margin: 0 auto;
            img {
                padding-bottom: 20px;
            }
        }*/
    }
}