#contact {
    display: block;
    width: 100%;
    background-color: $black;
    text-align: center;
    padding: 80px 0 135px 0;
    @include mobile {
        text-align: left;
    }
    @include small-mobile {
        text-align: left;
    }
    .content {
        h1 {
            .bold1 {
                font-weight: 700;
                color: $green;
                text-transform: uppercase;
            }
        }
        .contact-copy {
            width: 65%;
            margin: 0 auto;
            
            @include mobile {
                width: 100%;
            }
            @include small-mobile {
                width: 100%;
            }
        }
        #contactinfo {
            margin: auto;
            li {
                display: inline-block;
                @include mobile {
                    display: block;
                }
                @include small-mobile {
                    display: block;
                }
                p {
                    @include mobile {
                        display: block;
                    }
                    @include small-mobile {
                        display: block;
                    }
                    font-weight: 400;
                    font-size: 22px;
                    position: relative;
                    top: 40px;
                }
            }
            .cinfo-2 {
                margin: 0 25px;
                p {
                    font-weight: 700;
                    color: $green;
                    @include mobile {
                        display: none;
                    }
                    @include small-mobile {
                        display: none;
                    }
                }
            }
            a {
                color: $white;
            }
            a:active  {
                color: $white;
                text-decoration: none;
            }
            a:link {
                color: $white;
                text-decoration: none;
                transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;
            }
            a:visited {
                color: $white;
                text-decoration: none;
            }
            a:hover {
                color: $green;
                text-decoration: none;
            }
        }
    }
}