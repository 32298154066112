html, body {
    height: auto !important;
    min-height: 100% !important;
}
html {
    width: 100%;
    overflow-x: hidden!important;
}
body {
    padding: 0;
    margin: 0;
    background-color: $white;
    width: 100%;
    overflow-x: hidden;
}
img {
    border: 0;
    max-width: 100%!important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;  
}
.hide {
    visibility: hidden;
}
.clear {
    clear: both;
}
::-moz-selection {
    background-color: $green;
    color: $white;
}
::selection {
    background-color: $green;
    color: $white;
}
.dividerTop {
    height: 15px;
    background-color: $green;
    padding-left: 30px;
    padding-right: 30px;
    
    @include tablet {
        visibility: hidden;
    }
    @include mobile {
        visibility: hidden;
    }
    @include small-mobile {
        visibility: hidden;
    }
}
.divider1 {
    height: 15px;
    background-color: $green;
    padding-left: 30px;
    padding-right: 30px;
}
.divider2 {
    width: 100%;
    height: 15px;
    background-color: $white;
}
.divider3 {
    width: 100%;
    height: 15px;
    background-color: $black;
}
.divider4 {
    width: 100%;
    height: 11px;
    background: url(http://flashgraphicsinc.com/src/images/footer_border.png);
    background-repeat: repeat-x;
    position: relative;
    top: -10px;
}


/*Chrome Fix*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .pmargin {
        margin-bottom: 8px;
    }
}
/*End Chrome Fix*/