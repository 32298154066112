h1 { /*Headlines*/
    font-family: $primary-font;
    font-weight: 400;
    color: $white;
    font-size:70px;
    margin-bottom: 25px;
    @include mobile {
      font-size:60px;
    }
    @include small-mobile {
      font-size:50px;
    }
}

h2 { /*Results*/
    font-family: $primary-font;
    font-weight: 400;
    color: $white;
    font-size:30px;
    margin: 0;
    text-align: center;
}

h3 { /*Services*/
    font-family: $primary-font;
    font-weight: 400;
    text-transform:uppercase;
    color: $black;
    font-size:26px;
    margin: 0;
}

h4 { /*Nav*/
    font-family: $primary-font;
    font-weight: 400;
    color: $black;
    text-transform:uppercase;
    font-size:18px;
    margin: 0;
}

h5 { /*Buttons*/
    font-family: $primary-font;
    font-weight: 400;
    color: $white;
    font-size:20px;
    margin: 0;
    letter-spacing: .03em;
}

p {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  color: $white;
  font-size: 18px;
  line-height: 1.4em; 
}

.bold1 {
  font-weight: 700;
  color: $green;
  text-transform: uppercase; 
}

.bold2 {
  font-weight: 700;
  color: $black;
  text-transform: uppercase; 
}

.bold3 {
  font-weight: 700;
  color: $green;
  text-transform: uppercase; 
}

.bold4 {
  font-weight: 500; 
}