#nav {
    text-align: right;
    position: relative;
    top: -20px;
    z-index: 999999;
    width: 800px;
    float: right; 
    display: inline-block;
    @include small-desktop {
        left: 40px;
    }
    li {
        display: inline-block;
        @include small-desktop {
            margin-left: 10px;
        } 
        @include desktop {
            margin-left: 20px;
        }
        @include large-desktop {
            margin-left: 30px;
        }
        h4 {    
            position: relative;
            top: 10px;
            a:link {
                color: $black;
                text-decoration: none;
                background-color: none;
                padding: 15px 15px;
                transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;
            }
            a:visited {
                color: $black;
                text-decoration: none;
            }
            a:hover {
                color: $white;
                text-decoration: none;
                background-color: $green;
            }
            a:active {
                color: $white;
                text-decoration: none;
            }
        }
    }
}