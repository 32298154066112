#footer {
    padding: 20px 0 25px 0;
    border-bottom: 20px solid $green;   
    .content {
        width: 100%;
    }
    .copyright {
        display: inline;
        position: relative;
        p {
            text-align: center;
            margin: 0 auto;
            color: $black;
            font-size: 15px;
            font-weight: 400;
            line-height: 1em;
            text-align: center;
        }
    }
}