#services {
    width: 100%;
    overflow-x: wrap;
    background-color: $green;
    padding: 80px 0 130px 0;
    
    @include mobile {
        padding: 80px 0 110px 0;
    }
    @include small-mobile {
        padding: 80px 0 110px 0;
    }
    
    .content {
        .bold2 {
            font-weight: 700;
            color: $black;
            text-transform: uppercase;
        }
        .bold4 {
            font-weight: 500;
        }
    }
    #printing_services {
        position: relative;
        top: 60px;
        .service_banner {
            margin: auto;
            background: url(http://flashgraphicsinc.com/src/images/services_banner.png);
            width: 272px;
            height: 42px;
            background-repeat: no-repeat;
            margin-bottom: 28px;
            text-align: center;
            h3 {
                position: relative;
                font-size: 20px;
                top: 9px;
            }
        }
    }
}