#header {
    height: 190px;
    position: relative;
    width: 100%;
    
    @include tablet {
        height: 60px;
    }
    @include mobile {
        height: 60px;
    }
    @include small-mobile {
        height: 60px;
    }
    .phone {
        width: 190px;
        background-color: $green;
        height: 45px;
        position: absolute;
        right: 0;
        @include tablet { display: none }
        @include mobile { display: none }
        @include small-mobile { display: none }
        .phone-icon {
            width: 17px;
            height: 17px;
            position: relative;
            top: 3px;
            display: inline-block;
            margin: 0 10px;
        }
        h3 {
            font-size: 22px;
            color: $white;
            position: relative;
            top: 8px;
            text-align: center;
            display: inline-block;
        }
    }
    .logo {
        width: 350px;
        position: relative;
        top: 40px;
        @include small-desktop {
            right: 50px;
            padding-left: 20px;
        }
        @include tablet { display: none }
        @include mobile { display: none }
        @include small-mobile { display: none }
    }
    .mobile-logo {
        width: 220px;
        position: relative;
        top: 5px;
    }
}
