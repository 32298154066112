#sticky-header{
    top: 0;
    visibility: hidden;
    width: 100%;
    position: fixed;
    z-index: 120;
    margin: 0px auto;
    padding: 15px 0;
    background-color: $white;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.05);
    
    .menu-toggle {
        position: fixed;
        top: 15px;
        right: 15px;
    }
}

#nav-sticky {
    z-index: 999999;
    text-align: right;
    float: right;
    position: relative;
    top: 15px;
    
    li {
        display: inline-block;
        @include large-desktop {
            margin-left: 40px;
        }
        @include desktop {
            margin-left: 40px;
        }
        @include small-desktop {
            margin-left: 30px;
        } 
        h4 {
            a:link {
                color: $black;
                text-decoration: none;
                padding: 10px 15px;
                background-color: none;
                transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;
            }
            a:visited {
                color: $black;
                text-decoration: none;
            }
            a:hover {
                color: $white;
                text-decoration: none;
                background-color: $green;
            }
            a:active {
                color: $white;
                text-decoration: none;
            }
        }
    }
}